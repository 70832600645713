import React from "react";
import styled from "@emotion/styled";

export const BlogContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BlogContent = styled.div`
  max-width: 600px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 6rem;
`;

export const BlogWrapper = ({ children }) => (
  <BlogContainer>
    <BlogContent>{children}</BlogContent>
  </BlogContainer>
);
