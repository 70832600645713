import styled from "@emotion/styled";
import { css } from "@emotion/core";

const baseHeightPx = 16;

const headingFontSizeMinEm = 1;
const headingFontSizeMaxEm = 3;

export const headingStyle = css`
  font-family: Rubik, sans-serif;
  margin-top: 2rem;
`;

const numHeadings = 6;
const interpolate = new Array(numHeadings)
  .fill(0)
  .map(
    (_, idx) =>
      headingFontSizeMinEm +
      (idx / (numHeadings - 1)) * (headingFontSizeMaxEm - headingFontSizeMinEm)
  );

export const BlogPostStyle = styled.div`
  font-family: Karla, sans-serif;
  line-height: 1.2;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${headingStyle}
  }

  p {
    margin-bottom: 1rem;
    text-align: justify;
  }

  sup,
  sub {
    font-size: 0.75em;
  }

  img,
  table {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  hr {
    border: none;
    border-top: 1px solid #b5b5b5;
  }

  ${interpolate.map(
    (size, idx) => css`
      h${numHeadings - idx} {
        font-size: ${size}em;
      }
    `
  )}

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  figcaption {
    font-size: 0.85rem;
    margin-bottom: 1rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }

  /* Tables */

  th,
  td {
    padding: 0.25rem 1rem 0.25rem 1rem;
    text-align: center;
  }

  table th {
    font-weight: 700;
  }

  table {
    border-collapse: collapse;

    display: block;
  }
  table td,
  table th {
    border: 1px solid #d6d6d6;
  }

  table tr th:first-of-type {
    border: none;
  }
`;
