import React from "react";
import { BlogWrapper } from "../../components/BlogLayout";
import { BlogPostStyle } from "../../components/BlogPostStyle";
import Layout from "../../components/Layout";
import { Link } from "gatsby";

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <BlogWrapper>
            <BlogPostStyle>
              <h1>Thank you!</h1>
              <p>We will respond to your enquiry as soon as we can.</p>
              <Link to="/">Go home</Link>
            </BlogPostStyle>
          </BlogWrapper>
        </div>
      </div>
    </section>
  </Layout>
);
